.orderItem{
  background: transparent;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}
.loader{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color:rgba(255,255,255,0.5);
}
.orderInner{
  position: relative;
  &:hover{
    background-color: #FBFAAF;
  }
}
.orderDeadLine{
  width: 17px;
  height: 17px;
}

.productName{
  white-space: normal;
  margin-bottom: 1rem !important;
}