.is-restaurant-logo {
  &.has-max-width {
    max-width: 30%;
  }
}

@media screen and (max-width: 768px) {
  div.preview {
    display: none;
  }
}
