.NotificationsContainer{
	width: 100%;
	ul{
		li{
			a{	
				padding: 10px 10px 10px 30px;
				position: relative;
				display: block;
				color: #4a4a4a;
				&:before{
					position: absolute;
					content: '';
					width: 7px;
					height: 7px;
					background: #133b7b;
					border-radius: 50%;
					left: 10px;
					top: 50%;
					margin-top: -3px;
				}
				&:hover{
					background: #dbdbdb;
				}
				&.read{
					&:before{
						display: none;
					}
				}
				span{
					color: #3273dc;
				}
			}
		}
	}
	.emptyNoteContainer{
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
    	align-items: center;
	}
}

.noteIconContainer{
	position: relative;
	.noteIcon{
		font-size: 1.6em;
		line-height: 1em;
		color: #000;
	}
	.noteCounter{
		position: absolute;
		border-radius: 15px;
    	padding: 2px 6px;
    	left: 10px;
    	top: -7px;
	}
}

.noteDropDown{
	width: 100%;
	padding-right: 17px;
	position: relative;
	@media screen and (min-width: 1024px) {
		width: 320px;
		padding-right: 0px;
	}
	&.readProcess{
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			background: #000;
			opacity: 0.2;
		}
	}
}
.allReadConatiner{
	padding: 10px 10px 10px 30px;
}