.wrapper{
	width: 100%;
	height: 50vh;
	position: relative;
	&.fullSize{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9999;
	}
	&.overwrite{
		background-color: rgba(0,0,0,0.1);
	}
	.loader{
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}