.control{
	position: relative;
	.popup{
		display: none;
		position: absolute;
		top: -100%;
		left: 0;
		border-radius: 4px;
		border: 1px solid #cccccc;
		padding: 4px 6px;
		background: #fff;
		z-index: 1;
	}
	&:hover{
		.popup{
			display: block;
		}
	}
}