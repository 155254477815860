.loader-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  span{
    position: absolute;
    top: 50%;
    left: 50%;
  }
}