.colorPreview{
	width: 36px;
    height: 36px;
    flex: 1 0 auto;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    &.otherColor{
    	background-image: linear-gradient(135deg,#e01a3b,#e77240 19%,#ed9f45 27%,#f2c450 34%,#eed84f 41%,#d5da49 47%,#b8dc41 53%,#97de3b 59%,#7ece64 66%,#6eb48f 73%,#5c98b2 81%,#4272cd);
    }
}
.otherSizes{
	border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 2px 8px;
    span{
        display: inline-block;
        margin: 2px;
        border-radius: 2px;
        background-color: hsl(0, 0%, 90%);
        padding: 3px;
        white-space: nowrap;
    }
}
