.boardContainer{
    width: 100%;
	background: transparent !important;
    height: calc(100vh - 200px) !important;
    padding: 0;
}
.boardContainer > div{
    width: 100%;
    height: 100%;
}
.boardContainer .smooth-dnd-container{
    display: flex;
}
.productImage,
.storeImage{
	width: 40px;
}
.isDisabledProduct{
	background-color: #dbdbdb;
    border-color: #dbdbdb;
    box-shadow: none;
    color: #7a7a7a;
    opacity: .5;
}
.progressBar{
    width: 30px;
    height: 30px;
}