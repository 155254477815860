html {
  padding-top: 2.85rem !important;
  overflow-y: auto !important;
}

body {
  margin: 0;
  padding: 0;
}

.title {
  word-break: normal;
}

.section,
.hero-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.column {
  flex-basis: unset;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-footer {
  margin-top: auto;
}

.redux-toastr .toastr .close-toastr {
  opacity: 1;
  color: #fff;
}

.redux-toastr .toastr .rrt-left-container {
  width: 75px;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  width: 26px !important;
  height: 26px !important;
}

.redux-toastr .toastr .rrt-middle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 75px;
  min-height: 70px;
}

.error {
  color: red;
}

.error::before {
  display: inline;
  content: '⚠ ';
}
aside.aside{
  background-color: #202f61 !important;
  .aside-tools{
    background-color: #133b7b !important;
  }
  .menu-list{
    a{
      color: #fff;
      &.is-active,
      &:hover{
        background-color: #18244b;
      }
    }
  }
}

#root{
  .table{
    &.is-hoverable{
      tbody{
        tr{
          &:not(.is-selected){
            &:hover{
              background-color: #f5f5f5;
            }
          }
        }
      }
    }
  }
}
.button{
  &.blue-button{
    background-color: #133b7b;
    color: #fff;
    &:hover,
    &:focus{
      color: #fff;
    }
    &[disabled],
    &.disabled{
      background-color: #133b7b;
      color: #fff;
      opacity: 0.7;
    }
  }
}
.input[readonly],
.textarea[readonly]{
  cursor: not-allowed;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  box-shadow: none;
  color: #7a7a7a;
}

.b-checkbox.checkbox input[type=checkbox]:checked + .check.is-blue{
  border-color: #133b7b;
  background-color: #133b7b;
}

.b-checkbox.checkbox:hover input[type=checkbox]:not(:disabled) + .check.is-blue{
  border-color: #133b7b;
}
@media print {
  html{
    padding-top: 0px !important;
    body{
      padding: 0 !important;
    }
  }

  .stickers-component{
    #items-section,
    #notes-section,
    #comments-section{
      display: none;
    }
    #order-summary-section{
      margin-bottom: 0px;
      .table{
        width: auto;
        td, th{
          padding: 1px 0;
          font-size: 11px !important;
          line-height: 12px !important;
          color: #000;
          border: none;
        }
        th{
          .title{
            font-size: 11px !important;
            line-height: 12px !important;
            color: #000;
          }
        }
      }
    }
    .field-print-hide{
      display: none !important;
    }
    >.columns{
      margin: 3px;
      .column{
        padding: 0px !important;
        flex: 100%;
        text-align: left;
        margin-bottom: 5px;
        .columns{
          margin: 0px;
          flex-wrap: wrap;
        }
        .field{
          font-size: 11px !important;
          line-height: 12px !important;
          text-align: left;
          margin: 0;
          .control,
          .input{
            font-size: 11px !important;
            line-height: 12px !important;
            align-items: flex-start;
            text-align: left;
            height: auto;
            padding: 0;
            font-weight: 400;
            color: black;
          }
          .field-label{
            text-align: left;
            margin-right: 5px;
            padding: 0;
            label{
              color: black;
              white-space: nowrap;
            }
          }
        }
        &#general-column{
          hr{
            display: none;
          }
        }
        .card{
          border: none !important;
          .card-content{
            padding: 0px;
          }
          .card-header{
            display: block;
            padding-bottom: 2px;
            margin-bottom: 2px;
            .card-header-title{
              font-size: 12px !important;
              line-height: 12px !important;
              padding: 0;
              border: none;
              color: black;
              .icon{
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .receipt-component{
    
  }
  .shipment-label-component{
    .react-pdf__Document{
      display: inline-flex;
    }
  }
}
.button.has-icon{
  .icon{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.react-datepicker__header {
  background: none;
}

.react-datepicker__input-container {
  input {
    background-color: white;
    border-radius: 4px;
    color: #363636;
    border: 1px solid #dbdbdb;
    font-size: 1rem;
    height: 2.25em;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
  }

  input:hover {
    border-color: #b5b5b5;
  }
}

.react-datepicker {
  font-family: inherit;
  background-color: white;
  border-radius: 4px;
  color: #363636;
  border-color: #dbdbdb;
}

.react-datepicker__day {
  color: #4a4a4a;
}

.react-datepicker__day-name {
  color: #7a7a7a;
  font-weight: 600;
}

.react-datepicker__current-month {
  color: #363636;
  font-weight: 400;
}

.react-datepicker__header {
  border-bottom: 1px solid #dbdbdb;
}

.react-datepicker__day--selected {
  color: white;
}

#navbar-main{
  width: 100vw;
  padding-left: 0;
}

html.has-aside-mobile-transition aside.aside.main-sidebar{
  width: 14rem;
  display: block;
  left: -14rem;
  transition: all 250ms ease-in-out 50ms;
}

html.has-aside-mobile-transition body{
  width: 100vw;
  overflow-x: hidden;
}


html.has-aside-left.has-aside-expanded nav.navbar,
html.has-aside-left.has-aside-expanded body{
  padding-left: 0 !important;
}

html.has-aside-left.has-aside-expanded.has-aside-mobile-expanded body{
  padding-left: 14rem !important;
}


#navbar-main .navbar-brand {
  flex: 1 1;
}
#navbar-main .navbar-brand.is-right{
  flex: none;
}
html.has-aside-mobile-expanded aside.aside.main-sidebar{
  left: 0;
}

html.has-aside-mobile-expanded #navbar-main #navbar-menu{
  padding-right: 14rem;
}

html.has-aside-mobile-expanded nav.navbar{
  margin-left: 14rem;
  transition: all 250ms ease-in-out 50ms;
}

h1.title{
  font-size: 1.5rem;
}

html section.hero.is-hero-bar .hero-body{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.react-trello-lane > div{
  min-width: unset;
  width: 100%;
}

.react-trello-lane > div .smooth-dnd-container{
  height: 100%;
}

.react-trello-board .smooth-dnd-container.horizontal{
  display: flex;
  height: 100%;
}