.field-label{
  &.field-label-left{
    text-align: left;
  }
}
.table{
  .unavailable-item{
    background-color: #dbdbdb;
    border-color: #dbdbdb;
    box-shadow: none;
    color: #7a7a7a;
    opacity: .5;
    td{
      border-color: #dbdbdb;
    }
  }
}
.commeny-content{
  white-space: pre-line;
}

.card-content{
  position: relative;
  &.overlay{
    &:before{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}